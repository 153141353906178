import { ReactNode } from 'react'
import { useRouter } from 'next/compat/router'
import { AtButton, AtButtonVariant, AtLink, AtLinkVariant, MlRichText } from '@curran-catalog/curran-atomic-library'

import { normalizeButton } from '..'
import { environment } from '@config/environment'
import { TmCatalogProps } from 'pages/catalog/[[...slug]]'
import { SiteDomain } from 'types'

const DEFAULT_NO_RESULTS_LABEL = 'Sorry, we could not find the page you were looking for'
const DEFAULT_NO_RESULTS_CONTENT = `View the search results for this product or return to our ${
  environment.siteDomain === SiteDomain.FURNITURE ? 'furniture' : 'flooring'
} catalog to continue shopping.`
const NO_RESULTS_HELPER_TEXT = `Still can't find what you're looking for?`

type NoResultsBoundaryProps = {
  children: ReactNode
  nbHits?: number
  searchResultsRedirect?: string
} & Pick<TmCatalogProps, 'noResultsLabel' | 'noResultsContent' | 'noResultsButtons' | 'noResultsHelperText'>

export const NoResultsBoundary = ({
  noResultsLabel,
  noResultsContent,
  noResultsButtons,
  noResultsHelperText,
  children,
  nbHits,
  searchResultsRedirect = '/catalog',
}: NoResultsBoundaryProps) => {
  const router = useRouter()

  if (nbHits === 0) {
    return (
      <>
        <div className="flex flex-col justify-center items-center gap-8 text-secondary w-full max-w-xl mx-auto py-12 md:py-14">
          <h3 className="text-2xl">{`${noResultsLabel ?? DEFAULT_NO_RESULTS_LABEL}`}</h3>
          {noResultsContent ? <MlRichText text={noResultsContent} /> : <p>{DEFAULT_NO_RESULTS_CONTENT}</p>}
          <hr className="border-outlined-gray w-full my-3" />
          <div className="flex flex-row gap-4 md:gap-8">
            {Array.isArray(noResultsButtons) && noResultsButtons.length > 0 ? (
              noResultsButtons?.map((button) => (
                <AtButton key={`helper-button-${button.label}`} {...normalizeButton(button)} />
              ))
            ) : (
              <>
                <AtButton
                  key="helper-button-home"
                  label="View Search Results"
                  variant={AtButtonVariant.TERTIARY}
                  onClick={() => {
                    const redirect = router?.asPath.split('/')[2]
                      ? `${searchResultsRedirect}?query=${router?.asPath.split('/')[2].replaceAll('-', ' ')}`
                      : `${searchResultsRedirect}`
                    router?.push(redirect)
                  }}
                />

                <AtButton
                  key="helper-button-catalog"
                  label={environment.siteDomain === SiteDomain.FURNITURE ? 'Furniture Catalog' : 'Flooring Catalog'}
                  variant={AtButtonVariant.TERTIARY}
                  onClick={() => router && router.push('/catalog')}
                />
              </>
            )}
          </div>
          <div className="flex flex-row gap-2 my-6 text-sm">
            {noResultsHelperText ? (
              <MlRichText text={noResultsHelperText} />
            ) : (
              <>
                {NO_RESULTS_HELPER_TEXT}
                <AtLink href="/customer-service" label="Contact Customer Service" variant={AtLinkVariant.DEFAULT} />
              </>
            )}
          </div>
        </div>
      </>
    )
  }

  return <>{children}</>
}
