import { memo } from 'react'
import isEqual from 'react-fast-compare'
import { useConnector } from 'react-instantsearch'
import connectStats from 'instantsearch.js/es/connectors/stats/connectStats'
import type { StatsConnectorParams, StatsWidgetDescription } from 'instantsearch.js/es/connectors/stats/connectStats'

export type UseStatsProps = StatsConnectorParams

/** 
 * The useStats Hook isn’t currently available in React InstantSearch Hooks, but you can make it yourself using useConnector() along with connectStats.
 https://www.algolia.com/doc/api-reference/widgets/stats/react-hooks/ 
 */
export const useStats = (props?: UseStatsProps) => {
  return useConnector<StatsConnectorParams, StatsWidgetDescription>(connectStats, props)
}

const StatsComponent = () => {
  const { nbHits } = useStats()

  let text: string

  switch (nbHits) {
    case 0:
      text = `No items`
      break
    case 1:
      text = `1 item`
      break
    default:
      text = `${nbHits} items`
      break
  }

  return <span className="block text-right text-sm leading-4 lg:text-base lg:leading-5">{text}</span>
}

export const Stats = memo(StatsComponent, isEqual)
