import { memo, useEffect, useMemo, useRef } from 'react'
import { useRouter } from 'next/compat/router'
import isEqual from 'react-fast-compare'
import { useInfiniteHits, useInstantSearch } from 'react-instantsearch'
import {
  AtButton,
  AtButtonSize,
  AtButtonState,
  AtButtonVariant,
  OrProductListing,
} from '@curran-catalog/curran-atomic-library'

import { TmCatalogProps } from 'pages/catalog/[[...slug]]'
import { useSessionStorage } from '@hooks/use-session-storage'
import { normalizeButton, normalizeProductListing } from '@utils/normalize'
import { Hit } from 'instantsearch.js'
import { Cart, ContentfulSynsisalWeaveColorGroup } from 'types'

export const VirtualInfiniteHits = memo(() => {
  useInfiniteHits()
  return null
}, isEqual)

type InfiniteHitsComponentProps = {
  ctaCard: TmCatalogProps['ctaCard']
  loadMoreButton: TmCatalogProps['loadMoreButton']
  showRefinements: boolean
  synsisalWeaveColorGroupCollection?: ContentfulSynsisalWeaveColorGroup[]
  setActiveProduct?: (activeProduct: Hit) => void
  cart?: Cart
}

const InfiniteHitsComponent = ({
  ctaCard,
  loadMoreButton,
  showRefinements,
  setActiveProduct,
  synsisalWeaveColorGroupCollection,
  cart,
}: InfiniteHitsComponentProps) => {
  const router = useRouter()

  const [objectID, setStoredObjectID, removeStoredObjectID] = useSessionStorage<string | null>('objectID', null)
  const [_, setStoredProductIndexID] = useSessionStorage<string | null>('productIndexID', null)

  const { status } = useInstantSearch()

  const { hits, isFirstPage, isLastPage, showPrevious, showMore, sendEvent } = useInfiniteHits()

  const isLoading = status === 'loading' || status === 'stalled'

  const hitRef = useRef<HTMLDivElement>(null)
  // When the reference value is changed, it is updated without the need to refresh or re-render (useState).
  // returns only one value which is the actual data stored
  const shouldScrollRestore = useRef(true)

  useEffect(() => {
    // It checks if the window object is defined and if the scrollRestoration property exists in the window.history object.
    if (typeof window === 'undefined' || !('scrollRestoration' in window.history)) {
      return
    }

    let timer: NodeJS.Timeout

    // Checks if the user is using mobile Safari by using the window.navigator.userAgent
    // Manual doesn't work well on iOS Safari https://github.com/vercel/next.js/issues/20951#issuecomment-1231966865
    const ua = window.navigator.userAgent.toLowerCase()
    const isMobileSafari = /safari/.test(ua) && /iphone|ipod|ipad/.test(ua)
    window.history.scrollRestoration = isMobileSafari ? 'auto' : 'manual'

    // onBeforeUnload will be executed when the user tries to leave the page
    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      removeStoredObjectID()
      // deletes the returnValue property of the event
      delete event['returnValue']
    }

    // Restore the scroll position on page load or page refresh using a timer
    const triggerRestore = () => {
      if (shouldScrollRestore.current) {
        // This short delay helps React scroll to correct position after initial hydration
        timer = setTimeout(() => {
          shouldScrollRestore.current = false
          if (hitRef.current && objectID) {
            hitRef.current.scrollIntoView({ behavior: 'auto', block: 'center' })
          }
        }, 200)
      }
    }

    window.addEventListener('beforeunload', onBeforeUnload)
    router &&
      router.beforePopState(() => {
        shouldScrollRestore.current = true
        return true
      })

    // Initial load (e.g. page refresh)
    if (shouldScrollRestore.current) {
      triggerRestore()
    }

    return () => {
      // This function clears the timeout, removes the beforeunload event listener,
      // and removes the beforePopState listener when the component is unmounted
      clearTimeout(timer)
      window.removeEventListener('beforeunload', onBeforeUnload)
      router && router.beforePopState(() => true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectID, hitRef])

  const normLoadMoreButton = useMemo(() => normalizeButton(loadMoreButton), [loadMoreButton])

  const label = useMemo(
    () => (normLoadMoreButton?.label ? normLoadMoreButton?.label : 'Load more'),
    [normLoadMoreButton?.label],
  )

  return (
    <div className="flex flex-col items-center gap-10 w-full">
      {!isFirstPage && (
        <AtButton
          label={isLoading ? 'Loading' : 'Load Previous'}
          state={isLoading ? AtButtonState.DISABLED : AtButtonState.ENABLED}
          variant={AtButtonVariant.TERTIARY}
          onClick={showPrevious}
          className="max-w-[160px] w-full text-center"
          icon={isLoading ? { type: 'spinner' } : undefined}
          size={AtButtonSize.COMPACT}
        />
      )}

      <OrProductListing
        {...normalizeProductListing(
          hits,
          hitRef,
          router,
          objectID,
          setStoredObjectID,
          setStoredProductIndexID,
          ctaCard,
          setActiveProduct,
          synsisalWeaveColorGroupCollection,
          cart,
          sendEvent,
        )}
        isFiltersOpen={showRefinements}
      />

      <div className="flex flex-row justify-center gap-4 w-full">
        {!isLastPage && (
          <AtButton
            disabled={isLoading}
            label={isLoading ? 'Loading' : label}
            state={isLoading ? AtButtonState.DISABLED : AtButtonState.ENABLED}
            variant={normLoadMoreButton?.variant ?? AtButtonVariant.TERTIARY}
            onClick={showMore}
            className="max-w-[160px] w-full text-center"
            icon={isLoading ? { type: 'spinner' } : undefined}
            size={AtButtonSize.COMPACT}
          />
        )}
      </div>
    </div>
  )
}

export const InfiniteHits = memo(InfiniteHitsComponent, isEqual)
