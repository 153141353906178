/* eslint-disable no-console */

export function handleUnknownError(): (error: unknown) => void {
  return (error: unknown) => {
    if (error instanceof Error) {
      const message = error.message || String(error)
      console.error('Error:', message)
      throw error // Re-throw the error for propagation
    } else {
      const message = JSON.stringify(error) || String(error)
      console.error('Non-Error Error:', message)
      throw new Error('Unknown error occurred: ' + message) // Throw a generic error
    }
  }
}
