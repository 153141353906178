import { useCallback, useEffect, useMemo, useState } from 'react'
import type { Hit } from 'instantsearch.js'
import { OrModalProps, OrSampleContainerProps } from '@curran-catalog/curran-atomic-library'
import { useCart, useModal } from '.'
import { getErrorModalProps } from '@config/modal-error'
import router from 'next/router'
import {
  HandleClickProps,
  TypeSample,
  normalizeBordersSamples,
  normalizeColorSamples,
} from '@components/rug-builder/order-samples'
import { SampleCartDTO } from '@services/cart'
import { ContentfulObjFlooringBorderColor, ContentfulObjFlooringWeaveColor } from 'types'
import { formatPrice } from '@utils/price'
import { handleUnknownError } from '@utils/errors'

export const useDrawerSamples = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [algoliaActiveProduct, setAlgoliaActiveProduct] = useState<Hit | null>(null)
  const [samples, setSamples] = useState<OrSampleContainerProps[]>([])
  const [descriptionDrawer, setDescriptionDrawer] = useState<string>('')
  const { addSampleToCart, cart, removeItem, isLoading: IsCartLoading, isValidating } = useCart()
  const { isModalErrorOpen, setIsModalErrorOpen } = useModal()
  const [isLoading, setIsLoading] = useState(true)

  const [isOpenModal, setIsOpenModal] = useState(false)
  const [infoModal, setInfoModal] = useState<OrModalProps>({
    title: '',
    handleCloseModal: () => {
      setIsOpenModal(false)
    },
  })

  const errorModalProps = getErrorModalProps(
    () => {
      setIsModalErrorOpen(false)
      router.push('/customer-service')
    },
    () => {
      setIsModalErrorOpen(false)
    },
  )

  const sampleCartItem = useMemo(
    () => (sampleInfo: ContentfulObjFlooringWeaveColor | ContentfulObjFlooringBorderColor) => {
      return cart?.items.find(
        (itemCart) => itemCart.metadata && itemCart.metadata.contentful_id === sampleInfo.CONTENTFUL_ID,
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cart, IsCartLoading, isValidating],
  )

  const sampleCartItemByID = useMemo(
    () => (contentful_id: string) => {
      return cart?.items.find((itemCart) => itemCart.metadata && itemCart.metadata.contentful_id === contentful_id)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cart, IsCartLoading, isValidating],
  )

  const handleToggleSample = useCallback(
    async ({ id, name, type, addToCart, img, weaveId, borderId, borderName, cartId }: HandleClickProps) => {
      if (addToCart) {
        const sampleInfo: SampleCartDTO = {
          color: name,
          type,
          quantity: 1,
          weaveReference: weaveId,
          ...(type === TypeSample.BORDER && { borderReference: borderId, borderName }),
          imageAlt: img.alt,
          imageSrc: img.src,
          metadata: { contentful_id: id, type: 'sample', subType: type, link: window.location.href, brand: weaveId },
        }
        try {
          await addSampleToCart(sampleInfo)
        } catch (err) {
          setIsModalErrorOpen(true)
          handleUnknownError()(err)
        }
      } else {
        try {
          const cartIdToRemove = cartId || sampleCartItemByID(id)?.id
          if (cartIdToRemove) {
            await removeItem({ id: cartIdToRemove as number })
          }
        } catch (err) {
          setIsModalErrorOpen(true)
          handleUnknownError()(err)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cart, IsCartLoading, isValidating],
  )

  const handleActiveProductSamples = async (activeProduct: Hit | null, isUpdating?: boolean) => {
    if (activeProduct) {
      if (!isUpdating) {
        setSamples([])
        setDescriptionDrawer('')
        setIsDrawerOpen(true)
        setIsLoading(true)
      }

      const colorBorderSamples = await fetch(`/api/get-color-border-weave?productSlug=${activeProduct.nameSlug}`)
      const colorBorderSamplesJson = await colorBorderSamples.json()
      if (colorBorderSamplesJson.code === 404) {
        setIsModalErrorOpen(true)
        setIsDrawerOpen(false)
        throw new Error('Not possible to show samples')
      }

      const weaveColorBorders = colorBorderSamplesJson.data

      setAlgoliaActiveProduct(activeProduct)

      setDescriptionDrawer(weaveColorBorders.samplesDrawerDescription)

      const colorSamples = normalizeColorSamples({
        hasColorSamples: weaveColorBorders.hasSamples,
        colors: weaveColorBorders.colorGroup,
        handleToggleSample,
        sampleCartItem,
        weaveContentfulId: weaveColorBorders.CONTENTFUL_ID,
      })

      const priceSampleLabel = formatPrice(weaveColorBorders.swatch_price ?? 0)

      const sampleColorGroup = {
        titleContainer: weaveColorBorders.colorSamplesTitle
          ? `${weaveColorBorders.colorSamplesTitle} (${priceSampleLabel} each)`
          : `Rug Weave Samples (${priceSampleLabel} each)`,
        samplesGroup: colorSamples,
      }

      const borderSamples = normalizeBordersSamples({
        borders: weaveColorBorders.borderGroup,
        handleToggleSample,
        sampleCartItem,
        weaveContentfulId: weaveColorBorders.CONTENTFUL_ID,
        setInfoModal,
        infoModal,
        setIsOpenModal,
      })

      const priceBorderSampleLabel = formatPrice(weaveColorBorders.border_swatch_price ?? 0)

      const sampleBorderGroup = borderSamples.length > 0 && {
        titleContainer: weaveColorBorders.borderSamplesTitle
          ? `${weaveColorBorders.borderSamplesTitle} (${priceBorderSampleLabel} each)`
          : `Rug Border Samples (${priceBorderSampleLabel} each)`,
        samplesGroup: borderSamples,
      }

      setSamples([sampleColorGroup, ...(sampleBorderGroup ? [sampleBorderGroup] : [])])
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleActiveProductSamples(algoliaActiveProduct, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, IsCartLoading, isValidating])

  return {
    algoliaActiveProduct,
    isDrawerOpen,
    setIsDrawerOpen,
    handleActiveProductSamples,
    samples,
    descriptionDrawer,
    errorModalProps,
    isModalErrorOpen,
    setIsModalErrorOpen,
    isOpenModal,
    setIsOpenModal,
    infoModal,
    setInfoModal,
    isLoading,
    setIsLoading,
  }
}
