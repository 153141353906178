import { useCallback, useMemo, useState } from 'react'
import { useRouter } from 'next/compat/router'
import singletonRouter from 'next/router'
import dynamic from 'next/dynamic'
import { InstantSearch, InstantSearchSSRProvider } from 'react-instantsearch'

import type { UiState, IndexUiState } from 'instantsearch.js'

import { ServerState, TmCatalogProps } from 'pages/catalog/[[...slug]]'
import { environment } from '@config/environment'
import { NoResultsBoundary, VirtualConfigure, VirtualSearchbox, VirtualSortBy } from '@utils/algolia-widgets'
import { algoliaClient, flooringRefinements, furnitureRefinements } from '@services/algolia'

import { SiteDomain } from 'types'
import { VirtualRefinementList } from '@components/menu-filters/refinement-list'
import { VirtualInfiniteHits } from './algolia-widgets'
import { AtButtonState, OrDrawer, OrModal } from '@curran-catalog/curran-atomic-library'

import { useDrawerSamples } from '@hooks/use-drawer-samples'
import { useCart } from '@hooks/index'
import { createInstantSearchRouterNext } from 'react-instantsearch-router-nextjs'

const NavFilters = dynamic(() =>
  import(/* webpackChunkName: 'navbar-filters' */ '@components/navbar-filters').then((mod) => mod.NavFilters),
)
const MenuFilters = dynamic(() =>
  import(/* webpackChunkName: 'menu-filters' */ '@components/menu-filters').then((mod) => mod.MenuFilters),
)
const InfiniteHits = dynamic(() =>
  import(/* webpackChunkName: 'infinite-hits' */ '@templates/tm-catalog/algolia-widgets/infinite-hits').then(
    (mod) => mod.InfiniteHits,
  ),
)

export interface FurnitureQueryParameters {
  category?: string[]
  vendorName?: string[]
  materials?: string[]
  colors?: string[]
  collection?: string[]
  priceMin?: string
  priceMax?: string
}

export interface FlooringQueryParameters {
  cleanability?: string[]
  materialFamily?: string[]
  materialType?: string[]
  patterns?: string[]
  texture?: string[]
  style?: string[]
  types?: string[]
  usages?: string[]
}
export interface QueryParameters extends FurnitureQueryParameters, FlooringQueryParameters {
  query?: string
  page?: string
  sortBy?: string
  colors?: string[]
}

type CustomRouteState = {
  query?: string
  category?: string
  vendorName?: string
  collection?: string
  materials?: string
  types?: string
  materialFamily?: string
  materialType?: string
  patterns?: string
  texture?: string
  style?: string
  cleanability?: string
  usages?: string
  colors?: string
  sortBy?: string
  range?: string
  page?: string
} & UiState

const isFurniture = environment.siteDomain === SiteDomain.FURNITURE

const refinements = isFurniture ? furnitureRefinements : flooringRefinements

export const TmVirtualAlgolia = () => {
  return (
    <InstantSearchSSRProvider>
      <InstantSearch
        searchClient={algoliaClient}
        indexName={environment.algolia.recordsIndex}
        future={{ preserveSharedStateOnUnmount: true }}
      >
        <VirtualSearchbox />
        <VirtualSortBy />
        {refinements.map(({ options }, index) => (
          <VirtualRefinementList
            key={`${options?.attribute}-${index}`}
            attribute={options?.attribute as string}
            limit={options?.limit as number}
            showMore={options?.showMore as boolean}
            showMoreLimit={options?.showMoreLimit as number}
          />
        ))}
        <VirtualSearchbox />
        <VirtualInfiniteHits />
      </InstantSearch>
    </InstantSearchSSRProvider>
  )
}

export type FiltersProps = {
  showDesktopRefinements: boolean
  showMobileRefinements: boolean
  onShowDesktopRefinements: () => void
  onShowMobileRefinements: () => void
}

const TmAlgolia = ({
  ctaCard,
  loadMoreButton,
  noResultsLabel,
  noResultsContent,
  noResultsButtons,
  noResultsHelperText,
  serverState,
  url,
  serverFilterFacets,
}: Pick<
  TmCatalogProps,
  'ctaCard' | 'loadMoreButton' | 'noResultsLabel' | 'noResultsContent' | 'noResultsButtons' | 'noResultsHelperText'
> &
  ServerState) => {
  const { cart } = useCart()
  const router = useRouter()

  const [showDesktopRefinements, setDesktopShowRefinements] = useState(true)
  const [showMobileRefinements, setMobileShowRefinements] = useState(false)
  const [isCartPageLoading, setCartPageLoading] = useState<boolean>(false)

  const handleDesktopShowRefinements = useCallback(() => setDesktopShowRefinements((prevState) => !prevState), [])
  const handleMobileShowRefinements = useCallback(() => setMobileShowRefinements((prevState) => !prevState), [])

  const {
    isDrawerOpen,
    setIsDrawerOpen,
    handleActiveProductSamples,
    samples,
    descriptionDrawer,
    errorModalProps,
    isModalErrorOpen,
    setIsModalErrorOpen,
    isOpenModal,
    infoModal,
    isLoading,
  } = useDrawerSamples()

  const serverResults = useMemo(
    () => serverState?.initialResults[environment.algolia.recordsIndex].results[0],
    [serverState?.initialResults],
  )

  return (
    <>
      <OrDrawer
        isOpen={isDrawerOpen}
        title={isLoading ? 'Loading...' : 'Order Samples'}
        description={descriptionDrawer}
        sampleGroups={samples}
        handleCloseDrawer={() => setIsDrawerOpen(false)}
        actionButton={{
          label: 'View Cart',
          onClick: () => {
            setCartPageLoading(true)
            router && router.push('/cart')
          },
          state: isCartPageLoading ? AtButtonState.LOADING : AtButtonState.ENABLED,
          icon: isCartPageLoading ? { type: 'spinner' } : undefined,
          disabled: isCartPageLoading,
        }}
      />
      <InstantSearchSSRProvider {...serverState}>
        <InstantSearch
          searchClient={algoliaClient}
          indexName={environment.algolia.recordsIndex}
          insights={true}
          future={{ preserveSharedStateOnUnmount: true }}
          routing={{
            router: createInstantSearchRouterNext({
              serverUrl: url,
              singletonRouter,
            }),
            stateMapping: {
              stateToRoute(uiState) {
                const indexUiState: IndexUiState = uiState[environment.algolia.recordsIndex] || {}

                return {
                  query: indexUiState.query,

                  ...(environment.siteDomain === SiteDomain.FURNITURE
                    ? // Furniture refinements
                      {
                        category: indexUiState.refinementList?.category?.join(', '),
                        vendorName: indexUiState.refinementList?.vendorName?.join(', '),
                        collection: indexUiState.refinementList?.collection?.join(', '),
                        materials: indexUiState.refinementList?.materials?.join(', '),
                        range: indexUiState.range,
                      }
                    : // Flooring Refinements
                      {
                        types: indexUiState.refinementList?.types?.join(', '),
                        materialFamily: indexUiState.refinementList?.materialFamily?.join(', '),
                        materialType: indexUiState.refinementList?.materialType?.join(', '),
                        patterns: indexUiState.refinementList?.patterns?.join(', '),
                        texture: indexUiState.refinementList?.texture?.join(', '),
                        style: indexUiState.refinementList?.style?.join(', '),
                        cleanability: indexUiState.refinementList?.cleanability?.join(', '),
                        usages: indexUiState.refinementList?.usages?.join(', '),
                      }),

                  // Common refinements
                  colors: indexUiState.refinementList?.colors?.join(', '),

                  sortBy: indexUiState.sortBy,
                  page: indexUiState.page,
                } as UiState
              },
              routeToState(routeState: CustomRouteState) {
                return {
                  [environment.algolia.recordsIndex]: {
                    query: routeState.query,

                    refinementList: {
                      ...(environment.siteDomain === SiteDomain.FURNITURE
                        ? {
                            category: routeState.category?.split(', '),
                            vendorName: routeState.vendorName?.split(', '),
                            collection: routeState.collection?.split(', '),
                            materials: routeState.materials?.split(', '),
                            colors: routeState.colors?.split(', '),
                          }
                        : {
                            types: routeState.types?.split(', '),
                            materialFamily: routeState.materialFamily?.split(', '),
                            materialType: routeState.materialType?.split(', '),
                            patterns: routeState.patterns?.split(', '),
                            texture: routeState.texture?.split(', '),
                            style: routeState.style?.split(', '),
                            cleanability: routeState.cleanability?.split(', '),
                            usages: routeState.usages?.split(', '),
                            colors: routeState.colors?.split(', '),
                          }),
                    },

                    sortBy: routeState.sortBy,
                    ...(environment.siteDomain === SiteDomain.FURNITURE && { range: routeState.range }),
                    page: routeState.page,
                  } as UiState,
                }
              },
            },
          }}
        >
          {/* A virtual widgets but which doesn't render anything */}
          {/* https://www.algolia.com/doc/guides/building-search-ui/widgets/customize-an-existing-widget/react-hooks/#building-a-virtual-widget-with-hooks */}
          <VirtualConfigure serverFilterFacets={serverFilterFacets} serverQuery={serverResults?.query} />
          <VirtualSearchbox />
          <VirtualSortBy />
          {refinements.map(({ options }, index) => (
            <VirtualRefinementList
              key={`${options?.attribute}-${index}`}
              attribute={options?.attribute as string}
              limit={options?.limit as number}
              showMore={options?.showMore as boolean}
              showMoreLimit={options?.showMoreLimit as number}
            />
          ))}
          <VirtualInfiniteHits />

          <NoResultsBoundary
            nbHits={serverResults?.nbHits}
            noResultsLabel={noResultsLabel}
            noResultsContent={noResultsContent}
            noResultsButtons={noResultsButtons}
            noResultsHelperText={noResultsHelperText}
          >
            <NavFilters
              showDesktopRefinements={showDesktopRefinements}
              showMobileRefinements={showMobileRefinements}
              onShowDesktopRefinements={handleDesktopShowRefinements}
              onShowMobileRefinements={handleMobileShowRefinements}
            />
            <div className="flex flex-row gap-5">
              <MenuFilters
                serverFilterFacets={serverFilterFacets}
                showDesktopRefinements={showDesktopRefinements}
                showMobileRefinements={showMobileRefinements}
                onShowMobileRefinements={handleMobileShowRefinements}
              />

              <InfiniteHits
                ctaCard={ctaCard}
                loadMoreButton={loadMoreButton}
                showRefinements={showDesktopRefinements}
                setActiveProduct={handleActiveProductSamples}
                cart={cart}
              />
            </div>
          </NoResultsBoundary>
        </InstantSearch>
      </InstantSearchSSRProvider>

      <OrModal isOpen={isOpenModal} {...infoModal} />
      <OrModal
        isOpen={isModalErrorOpen}
        title="Error"
        description={errorModalProps.description}
        leftButton={errorModalProps.leftButton}
        rightButton={errorModalProps.rightButton}
        handleCloseModal={() => setIsModalErrorOpen(false)}
      />
    </>
  )
}

export default TmAlgolia
