import { memo } from 'react'
import isEqual from 'react-fast-compare'

import { ServerState } from 'pages/catalog/[[...slug]]'
import { useConfigure } from 'react-instantsearch'

export const VirtualConfigure = memo(
  ({
    serverFilterFacets = '',
    serverQuery = '',
  }: Pick<ServerState, 'serverFilterFacets'> & { serverQuery?: string }) => {
    useConfigure({
      filters: serverFilterFacets,
      query: serverQuery,
      clickAnalytics: true,
    })

    return null
  },
  isEqual,
)
