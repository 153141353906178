import { memo, useCallback, useMemo } from 'react'
import { useSortBy } from 'react-instantsearch'
import isEqual from 'react-fast-compare'
import { MlFormField, SelectOption } from '@curran-catalog/curran-atomic-library'

import { sortsFlooring, sortsFurniture } from '@services/algolia'
import { SiteDomain } from 'types'
import { environment } from '@config/environment'

const isFlooringSite = environment.siteDomain === SiteDomain.FLOORING
const isSisalcarpetSite = environment.siteDomain === SiteDomain.SISALCARPET
const isSynsisalSite = environment.siteDomain === SiteDomain.SYNSISAL

const items = isFlooringSite || isSisalcarpetSite || isSynsisalSite ? sortsFlooring : sortsFurniture

export const VirtualSortBy = memo(() => {
  useSortBy({ items })
  return null
}, isEqual)

const SortByComponent = () => {
  const { currentRefinement, refine } = useSortBy({ items })

  const currentOption: SelectOption | undefined = useMemo(
    () => items.find((item) => item.value === currentRefinement),
    [currentRefinement],
  )

  const handleSelectChange = useCallback(
    (selectedOption: SelectOption) => {
      if (selectedOption) {
        refine(selectedOption.value)
      }
    },
    [refine],
  )

  return (
    <MlFormField
      className="bg-white w-full"
      type="select"
      currentOption={currentOption}
      placeholder="Select"
      options={items}
      filterHandler={handleSelectChange}
    />
  )
}

export const SortBy = memo(SortByComponent, isEqual)
