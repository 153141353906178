import { memo } from 'react'
import isEqual from 'react-fast-compare'
import { useRefinementList } from 'react-instantsearch'
import { MlFormField } from '@curran-catalog/curran-atomic-library'

export const VirtualRefinementList = ({
  attribute,
  limit,
  showMoreLimit,
  showMore,
}: {
  attribute: string
  limit: number
  showMoreLimit: number
  showMore: boolean
}) => {
  useRefinementList({
    attribute,
    limit,
    showMoreLimit,
    showMore,
  })

  return null
}

const RefinementListComponent = ({
  attribute,
  limit,
  showMoreLimit,
  showMore,
}: {
  attribute: string
  limit: number
  showMoreLimit: number
  showMore: boolean
}) => {
  const { items, isShowingMore, canToggleShowMore, refine, toggleShowMore } = useRefinementList({
    attribute,
    limit,
    showMoreLimit,
    showMore,
  })

  if (items.length < 1)
    return (
      <div className="flex flex-col gap-4 flex-wrap pl-4 mb-9">
        <span>No items to filter</span>
      </div>
    )

  return (
    <ul className="flex flex-col flex-wrap pl-4 mb-9">
      {items.map(({ label, value, isRefined, count }, idx) => (
        <li key={`filter-${idx}-${label}`}>
          <MlFormField
            fieldId={`filter-${idx}-${label}`}
            label={`${label} (${count})`}
            name={label}
            onChange={() => refine(value)}
            type="checkbox"
            value=""
            checked={isRefined}
            className="!w-full min-h-[2.5rem]"
          />
        </li>
      ))}
      {canToggleShowMore && limit <= items.length && (
        <button className="text-left hover:underline" onClick={() => toggleShowMore()}>
          {!isShowingMore ? 'Show more' : 'Show less'}
        </button>
      )}
    </ul>
  )
}

export const RefinementList = memo(RefinementListComponent, isEqual)
